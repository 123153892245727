var gr_data = {};

export function store( obj )
{
	gr_data = obj;
} 


export function restore( )
{
	return gr_data;
} 